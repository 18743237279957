
import React, { Fragment } from 'react'
import '../templates/nova-loja.scss'
// import { graphql, useStaticQuery } from 'gatsby'
// import RenderForm from 'gatsby-plugin-cloudofertas'
import FormNovaLoja from '../components/form-nova-loja/form-nova-loja'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import LocatorNovaLoja from '../components/Locator/LocatorNovaLoja.js'
// import CounterNovaLoja from '../components/counter/counter-nova-loja'

const Cupom = ({ data }) => {
  return (
    <Fragment id="blackleste">
      <div className='fundo-cinza-claro-cupom'>
        <div className='container'>
          <p className='text-center ja-conheceu-cupom'><b>Já conheceu a Drogaleste da Penha?</b></p>
          <div className='mt-5'>
            <p className='d-none d-md-block text-center' style={{ color: '#D9304F', fontSize: '45px' }}><b>Se você ainda não foi e não tem cadastro, <br></br>
              estamos te presenteando com R$20 para conhecer</b></p>
            <p className='d-block d-md-none text-center' style={{ color: '#D9304F', fontSize: '45px' }}><b>Se você ainda não foi e não tem cadastro,
              estamos te presenteando com R$20 para conhecer</b></p>
          </div>
          <div className='row mt-2'>
            <div className='col-lg-4 col-12'>
              {/* <div className='d-block' > */}
              <div className='d-flex align-items-center'>
                <div style={{ backgroundColor: '#03588C', borderRadius: '80px', width: '15%', height: '45px', paddingTop: '1px' }} className='mr-2'>
                  <p className='text-center align-middle' style={{ color: 'white', fontSize: '30px' }}>1</p>
                </div>
                <div style={{ height: '5px', width: '100%' }} className='d-flex align-items-center'>
                  <div style={{ height: '1px', backgroundColor: '#03588C', width: '37.5%' }}></div>
                </div>
              </div>
              <p className='text-left' style={{ fontSize: '30px' }}>Cadastra-se</p>
              {/* </div> */}
            </div>
            <div className='col-lg-4 col-12'>
              <div className='d-flex align-items-center'>
                <div style={{ backgroundColor: '#03588C', borderRadius: '80px', width: '15%', height: '45px', paddingTop: '1px' }} className='mr-2'>
                  <p className='text-center align-middle' style={{ color: 'white', fontSize: '30px' }}>2</p>
                </div>
                <div style={{ height: '5px', width: '100%' }} className='d-flex align-items-center'>
                  <div style={{ height: '1px', backgroundColor: '#03588C', width: '80%' }}></div>
                </div>
              </div>
              <p className='text-left' style={{ fontSize: '30px' }}>Você receberá uma mensagem de validação</p>
            </div>
            <div className='col-lg-4 col-12'>
              <div className='d-flex align-items-center'>
                <div style={{ backgroundColor: '#03588C', borderRadius: '80px', width: '15%', height: '45px', paddingTop: '1px' }} className='mr-2'>
                  <p className='text-center align-middle' style={{ color: 'white', fontSize: '30px' }}>3</p>
                </div>
                <div style={{ height: '5px', width: '100%' }} className='d-flex align-items-center'>
                  <div style={{ height: '1px', backgroundColor: '#03588C', width: '80%' }}></div>
                </div>
              </div>
              <p className='text-left' style={{ fontSize: '30px' }}>Pronto! Seu cupom estará válido para uso na <b>na loja da Penha.</b></p>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-7 col-12'>
              <GatsbyImage
                image={data.mulhercomcelular.childImageSharp.gatsbyImageData}
                alt='Imagem teste covid'
                className='mulher-com-celular'
              />
            </div>
            <div className='col-lg-5 position-relative col-12 mt-5'>
              <FormNovaLoja />
              <div>
                <p className='text-center mt-2 texto-mobile-final-cinza'>Este cupom é válido somente nas compras acima de R$80, nesta loja e no dia da abertura, limitado somente a um por CPF*</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ position: 'relative', alignItems: 'center' }} className='d-flex justify-content-center'>
        <div className='localizacao-endereco'>
          <p className='texto-endereco'>Av. Penha de França, 2319 - Penha de França</p>
        </div>
      </div>
      <div id='comochegar'>
        <LocatorNovaLoja />
      </div>
    </Fragment >
  )
}
export default Cupom
export const query = graphql`
query {
  lojadestaque: file(relativePath: {eq: "images/novaloja/loja-destaque.png"}){
    childImageSharp {
      gatsbyImageData(quality: 100)
    }
  }
  leiteninho1: file(relativePath: {eq: "images/novaloja/leiteninho1.png"}){
    childImageSharp {
      gatsbyImageData(quality: 100)
    }
  }
  shampoo: file(relativePath: {eq: "images/novaloja/shampoo.png"}){
    childImageSharp {
      gatsbyImageData(quality: 100)
    }
  }
  pastadedente: file(relativePath: {eq: "images/novaloja/pastadedente.png"}){
    childImageSharp {
      gatsbyImageData(quality: 100)
    }
  }
  pastadedente1: file(relativePath: {eq: "images/novaloja/pastadedente1.png"}){
    childImageSharp {
      gatsbyImageData(quality: 100)
    }
  }
  sustagem: file(relativePath: {eq: "images/novaloja/sustagem.png"}){
    childImageSharp {
      gatsbyImageData(quality: 100)
    }
  }
  remedio: file(relativePath: {eq: "images/novaloja/remedio.png"}){
    childImageSharp {
      gatsbyImageData(quality: 100)
    }
  }
  lavitan: file(relativePath: {eq: "images/novaloja/lavitan.png"}){
    childImageSharp {
      gatsbyImageData(quality: 100)
    }
  }
  mulhercomcelular: file(relativePath: {eq: "images/novaloja/mulhercomcelular.png"}){
    childImageSharp {
      gatsbyImageData(quality: 100)
    }
  }
  icon: file(relativePath: {eq: "images/novaloja/icon.png"}){
    childImageSharp {
      gatsbyImageData(quality: 100)
    }
  }
  fundocinzaescuro: file(relativePath: {eq: "images/novaloja/fundo-cinza-escuro.png"}){
    childImageSharp {
      gatsbyImageData(quality: 100)
    }
  }
  loja: file(relativePath: {eq: "images/novaloja/loja.png"}){
    childImageSharp {
      gatsbyImageData(quality: 100)
    }
  }
}
`
